import React from "react"

import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"
import { LogoQuery } from "./__generated__/LogoQuery"
import { ServicesListQuery_allMdx_edges } from "../templates/__generated__/servicesListQuery"
import { IndexPageQuery_services_edges } from "../pages/__generated__/IndexPageQuery"

const Logo = ({ className = "logo" }) => {
    const data = useStaticQuery<LogoQuery>(graphql`
        query LogoQuery {
            site {
                siteMetadata {
                    title
                    logo
                }
            }
        }
    `)

    return (
        <img
            src={data.site.siteMetadata.logo}
            alt={`${data.site.siteMetadata.title} - logo`}
            className={className}
        />
    )
}

const Link = props => {
    if (props.to) {
        if (props.to.startsWith("/")) {
            return <GatsbyLink {...props}>{props.children}</GatsbyLink>
        }

        return <a href={props.to}>{props.children}</a>
    } else {
        return (
            <button {...props}></button>
        )
    }
}

const serviceListOrder = [
    "tree removal",
    "tree pruning",
    "hedges",
    "storm damage",
    "ivy removal",
    "firewood",
    "hardwood planks",
    "planting",
]

function sortServices(
    servicesData:
        | ServicesListQuery_allMdx_edges[]
        | IndexPageQuery_services_edges[]
) {
    return servicesData.sort((a, b) => {
        const aTitle = a.node.frontmatter.title.toLowerCase()
        const bTitle = b.node.frontmatter.title.toLowerCase()
        return (
            serviceListOrder.indexOf(aTitle) - serviceListOrder.indexOf(bTitle)
        )
    })
}


export { Logo, Link, sortServices }
